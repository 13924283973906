<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getCloseSellReport"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-center">
            <h2 id="closesellTitle" class="mb-0 font-weight-normal">
              {{ $t('report.txtCloseSale') }}
            </h2>
            <HelpButton :isHeader="false" class="ml-2"></HelpButton>
            </div>
            <div
              v-if="isExport"
              class="col-md-2 col-sm-3 col-4 text-right text-success"
            >
              <CButton id="closesellExportButton" v-on:click="exportCloseSellReport()" block color="info">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <hr />
              <div style="overflow-x:auto;">
                <DataTable
                  id="closesellDatatable"
                  :items="items()"
                  :fields="fields"
                  :loading="loading"
                  hover
                  border
                  style="white-space: nowrap;"
                >
                <template #difference="{ item }">
                  <td v-if="floatValue(item.difference) < 0.00" class="text-right font-weight-normal text-danger">{{ item.difference }}</td>
                  <td v-else class="text-right font-weight-normal text-dark">{{ item.difference }}</td>
              </template>
                </DataTable>
              </div>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getCloseSellReport">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import Pagination from '@/containers/Pagination'
import permis from '@/util/permission'
import HelpButton from '../../containers/HelpButton.vue'

export default {
  components: {
    DataTable,
    Pagination,
    HelpButton
  },
  data() {
    return {
      data: [],
      loadingButton: true,
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    startDate() {
      return moment(this.date.start).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(this.date.end).format('DD MMMM YYYY')
    },
    fields() {
      return [
        { key: 'openAt', label: this.$i18n.t('date'), _classes: 'text-dark font-weight-normal' },
        {
          key: 'orderMachine',
          label: this.$i18n.t('orderMachine'),
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'roundNo',
          label: this.$i18n.t('roundNo'),
          _classes: 'text-center text-dark font-weight-normal',
        },
        {
          key: 'closeAt',
          label: this.$i18n.t('closeAt'),
          _classes: 'text-center text-dark font-weight-normal',
        },
        {
          key: 'updatedBy',
          label: this.$i18n.t('closeBy'),
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: 'totalCashSales',
          label: this.$i18n.t('totalCashSales'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'initialChange',
          label: this.$i18n.t('initialChange'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'totalCashIn',
          label: this.$i18n.t('totalCashIn'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'totalCashOut',
          label: this.$i18n.t('totalCashOut'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'actualInDrawer',
          label: this.$i18n.t('actualInDrawer'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'expectedInDrawer',
          label: this.$i18n.t('expectedInDrawer'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'difference',
          label: this.$i18n.t('difference'),
          _classes: 'text-right font-weight-normal text-dark',
        },
      ]
    },
    
  },
  created() {
    this.getCloseSellReport()
  },
  methods: {
    ...util,
    items() {
      const data = this.data || []
      let detail = []

      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        let closeAt = '-'
        if (item.closeAt == undefined) {
          closeAt = '-'
        } else {
          closeAt = moment(item.closeAt).format('DD/MM/YYYY HH:mm')
        }

        let updatedBy = ''
        if (item.updated_by == undefined || item.closeAt == undefined) {
          updatedBy = '-'
        } else {
          if (item.updated_by.username == undefined) {
            updatedBy = '-'
          } else {
            updatedBy = item.updated_by.username
          }
        }

        let round = {
          openAt: moment(item.openAt).format('DD/MM/YYYY HH:mm'),
          roundNo: item.roundNo,
          orderMachine: item.pos.posNumber || '-',
          closeAt: '-',
          updatedBy: '-',
          initialChange: util.convertCurrency(item.initialChange),
          totalCashIn: util.convertCurrency(item.totalCashIn),
          totalCashOut: util.convertCurrency(item.totalCashOut),
          actualInDrawer: '-',
          expectedInDrawer: '-',
          totalCashSales: util.convertCurrency(item.totalCashSales),
          difference: '-',
        }
        
        if (item.closeAt != undefined) {
          round['closeAt'] = closeAt
          round['updatedBy'] = updatedBy
          round['actualInDrawer'] = util.convertCurrency(item.actualInDrawer)
          round['expectedInDrawer'] = util.convertCurrency(item.expectedInDrawer)
          round['difference'] = util.convertCurrency(item.difference)
        } 
        detail.push(round)
      }
      return detail
    },
    getCloseSellReport() {
      this.loadingButton = false
      this.loading = true
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        limit: 50,
      }

      const headers = { shopObjectId: shopObjectId }

      axios({
        url: '/cash/v1.0/' + uid + '/cashround',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportCloseSellReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }
      const headers = { shopObjectId: shopObjectId }

      report({
        url: '/cash/v1.0/' + uid + '/cashround/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานปิดรอบการขาย.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    floatValue(value) {
      return parseFloat(value)
    }
  },
}
</script>
